<template>
  <v-col>
    <edit-employee
      @closeModal="closeModall"
      @edit="updateEdit"
      v-model="modalpopup"
      :user="user"
      title="Редактирование пользователя"
    ></edit-employee>
    <v-card class="titleCard px-5 py-2">
      <v-chip class="on-border-right pr-0 hidden-sm-and-down" color="orange">
        <span>Последний вход </span>
        <v-chip color="green" class="ml-2">
          {{ user.lastVisit | moment }}
        </v-chip>
      </v-chip>
      <div class="on-border-left">
        <v-chip
          v-if="!!this.user.accountSetting.airport"
          class="pr-0"
          color="orange"
        >
          <span>Аэропорт </span>
          <v-chip color="green" class="ml-2">
            {{ user.accountSetting.airport.nameRu }}
          </v-chip>
        </v-chip>
        <v-chip
          v-if="!!this.user.accountSetting.bpoCompany"
          class="hidden-sm-and-down pr-0"
          style="margin-left:10px"
          color="orange"
        >
          <span>Компания </span>
          <v-chip color="green" class="ml-2">
            {{ user.accountSetting.bpoCompany.name }}
          </v-chip>
        </v-chip>
      </div>
      <v-row class="py-3" align="center">
        <v-col sm="12" md="2">
          <v-img
            :src="
              user.role === $enums.Roles.Admin
                ? require('@/assets/img/admin-ico.png')
                : require('@/assets/img/cashierLogo.png')
            "
            :max-height="$vuetify.breakpoint.mobile ? '120' : '120'"
            contain
            class="mt-2"
          ></v-img>
        </v-col>

        <v-col xs="6" sm="8" md="14">
          <v-row justify="center" justify-md="start" align="center">
            <v-col cols="auto" class="text-md-left text-center">
              <span
                class="text-md-h3 text-h5 orange--text darken-4--text"
                @click="CopyToBuffer(user.name)"
              >
                {{ user.name }}
              </span>
            </v-col>
            <v-col md="auto" class="text-center">
              <span
                class="text-h5 text-md-h5 font-weight-bold"
                @click="CopyToBuffer(user.email)"
              >
                {{ user.email }}
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="$store.getters.user.role === $enums.Roles.Admin"
            class="mt-5"
          >
            <v-col md="6" class="text-center" align-self="center">
              <v-select
                :items="usingBallance"
                v-model="isUseBallance"
                @change="onChangeBallanceUsing"
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="this.$store.getters.user.role === this.$enums.Roles.Admin"
          cols="6"
          md="2"
        >
          <v-row>
            <v-col sm="2" md="8" class="text-md-right">
              <v-btn color="orange" v-on:click="modalpopup = true" dark>
                <v-icon>mdi-account-edit-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col sm="2" md="8" class="text-md-right">
              <v-btn color="red" dark v-on:click="this.onDelete">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-divider
        v-if="$store.getters.user.role === $enums.Roles.Admin"
      ></v-divider>
      <v-row
        v-if="$store.getters.user.role === $enums.Roles.Admin"
        class="mt-4"
        v-show="isUseBallance"
      >
        <v-expand-transition>
          <v-row justify="center" align="center">
            <v-col cols="auto" class="text-center">
              <v-row class="text-center">
                <v-col cols="auto" align-self="center">
                  <span class="text-h5 green--text">
                    Текущий баланс: {{ user.accountSetting.ballance }} Руб
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" align-self="end">
                  <v-text-field
                    label="Добавить"
                    :rules="[rules.isNumber]"
                    v-model="AddingSum"
                    outlined
                    suffix="Руб"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-subheader>
                    <v-btn
                      :disabled="!sumIsNumber"
                      @click="AddSum(AddingSum)"
                      large
                      color="orange"
                      >ADD</v-btn
                    >
                  </v-subheader>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto" class="text-center">
              <v-row class="text-center">
                <v-col cols="auto" align-self="center">
                  <span class="text-h5 green--text">
                    Текущий сбор: {{ user.accountSetting.tax }} Руб
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" align-self="end">
                  <v-text-field
                    label="Установить"
                    :rules="[rules.isNumber]"
                    v-model="settingTax"
                    outlined
                    suffix="Руб"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-subheader>
                    <v-btn
                      :disabled="!taxIsNumber"
                      @click="SetTax(settingTax)"
                      large
                      color="orange"
                    >
                      SET
                    </v-btn>
                  </v-subheader>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import DateFormat from "@/mixins/DateFormat";
import EditEmployee from "@/components/EmployeePage/EditEmployee.vue";
import ApiCalls from "@/mixins/booking/ApiCalls";
import ConfirmDialog from "@/components/UI/ConfirmDialog";
import ClipboardData from "@/mixins/ClipboardData";

export default {
  name: "EmployeeItem",
  data() {
    return {
      modalpopup: false,
      usingBallance: [
        {
          text: "Не использовать ограничения",
          value: false,
        },
        {
          text: "Использовать ограничения",
          value: true,
        },
      ],
      AddingSum: "",
      settingTax: "",
      isUseBallance: false,
      rules: {
        isNumber: (value) => isFinite(value) || "Введите число",
      },
    };
  },
  components: { EditEmployee, ConfirmDialog },
  mixins: [DateFormat, ApiCalls, ClipboardData],
  props: ["user", "number"],
  mounted() {
    this.isUseBallance = this.user.accountSetting.useBallance;
  },
  methods: {
    async AddSum(val) {
      let mySumReq = {
        Id: this.user.id,
        AccountSetting: {
          AddingSum: val,
        },
      };
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Account.Edit,
          mySumReq
        );
        this.user.accountSetting.ballance += parseFloat(val);
        if (this.user.id == this.$store.state.user.id) {
          this.$store.state.user.accountSetting.ballance += parseFloat(val);
        }
        console.warn(resp.data);
        this.AddingSum = "";
        return resp.data;
      } catch (e) {
        console.log("SetUserBallance - Error");
        console.log(e.response);
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
        return [];
      }
    },
    async SetTax(val) {
      let mySetTaxReq = { Id: this.user.id, AccountSetting: {
        Tax: val
      } };
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Account.Edit,
          mySetTaxReq
        );
        this.user.accountSetting.tax = parseFloat(val);
        if (this.user.id == this.$store.state.user.id) {
          this.$store.state.user.accountSetting.tax += parseFloat(val);
        }
        this.settingTax = "";
        return resp.data;
      } catch (e) {
        console.log("SetUserBallance - Error");
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
        return [];
      }
    },
    async onChangeBallanceUsing() {
      await this.setBallanceUsing();
    },
    async setBallanceUsing() {
      let mySetBallanceUsingReq = {
        Id: this.user.id,
        AccountSetting: {
          UseBallance: this.isUseBallance,
        },
      };
      try {
        let resp = await this.$axios.post(
          this.$enums.Endpoints.Account.Edit,
          mySetBallanceUsingReq
        );
        if (this.user.id == this.$store.state.user.id) {
          this.$store.state.user.useBallance = !this.$store.state.user
            .useBallance;
        }
        console.warn("Ограничения изменены");
        return resp.data;
      } catch (e) {
        console.log("SetUserBallanceUsing - Error");
        this.useBallance = !this.useBallance;
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
        return [];
      }
    },

    onDelete() {
      this.$store.dispatch({
        type: "setActions",
        accept: () => {
          this.deleteEmployee(this.user.id);
        },
      });
      this.$store.commit("setMessage", "что хотите удалить");
      this.$store.commit("openDialog", true);
    },
    closeModall() {
      this.modalpopup = false;
    },

    updateEdit(newUser) {
      this.user = newUser;
    },
    async deleteEmployee(value) {
      let resp = await this.$axios.post(
        this.$enums.Endpoints.Account.Delete + `?value=${value}`
      );
      this.$emit("deleteFromList", this.user);
      return resp.data;
    },
  },
  computed: {
    sumIsNumber() {
      return isFinite(this.AddingSum) && this.AddingSum !== "";
    },
    taxIsNumber() {
      return isFinite(this.settingTax) && this.settingTax !== "";
    },
  },

  filters: {
    Role(value) {
      switch (value) {
        case 0:
          return "Администратор";
        case 1:
          return "Кассир";
        default:
          return `Неизвестная роль - ${value}`;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .on-border-right {
    transform: translateX(-50%);
    left: 50%;
    right: inherit !important;
  }
  .on-border-left {
    transform: translateX(50%);
    right: 50%;
    left: inherit !important;
  }
}
.on-border-right {
  position: absolute;
  top: -15px;
  right: 20px;
}
.on-border-left {
  position: absolute;
  top: -15px;
  left: 20px;
}

.titleCard {
  border: solid 2px orange !important;
}
</style>
